import AOS from "aos";

/*
-------------------------------
Animaciones scroll
-------------------------------
*/
AOS.init();

var scrollRef = 0;
window.addEventListener("scroll", function () {
	// increase value up to 10, then refresh AOS
	if (scrollRef <= 10) {
		scrollRef++;
	} else {
		AOS.refresh();
	}
});

document.addEventListener("DOMContentLoaded", function () {
	/*
	-------------------------------
	Validar forma
	-------------------------------
	*/
	var defaultForm = document.querySelector("#formaContacto");
	if (defaultForm) {
		var nombre = defaultForm.querySelector("#nombre");
		var email = defaultForm.querySelector("#email");
		var tel = defaultForm.querySelector("#tel");

		defaultForm.addEventListener("submit", function (e) {
			if (
				nombre.value === "" ||
				nombre.value === null ||
				nombre.value === false
			) {
				e.preventDefault();
				nombre.classList.add("is-invalid");
			} else {
				nombre.classList.remove("is-invalid");
			}

			if (
				email.value === "" ||
				email.value === null ||
				email.value === false
			) {
				e.preventDefault();
				email.classList.add("is-invalid");
			} else {
				email.classList.remove("is-invalid");
			}

			if (tel.value === "" || tel.value === null || tel.value === false) {
				e.preventDefault();
				tel.classList.add("is-invalid");
			} else {
				tel.classList.remove("is-invalid");
			}
		});
	}
});
